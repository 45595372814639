<template>
  <div class="AuxiliaryReading">
    <heade></heade>
    <div class="con_wrap">
      <div class="con_box">
        <el-button
          type="text "
          icon="el-icon-arrow-left"
          @click="$router.back(-1)"
          >返回上一页</el-button
        >
        <div class="text nice-scroll">
          <img src="../../assets/images/fuwuqi1.jpg" alt="" />
          <img src="../../assets/images/fuwuqi2.jpg" alt="" />
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import heade from "@/components/heade.vue";
import foot from "@/components/foot.vue";

export default {
  components: {
    heade,
    foot,
  },
  data() {
    return {};
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
  mounted: function () {
    scroll($(".nice-scroll"));
  },
  created: function () {
    document.title = this.$route.meta.title;
  },
};
</script>

<style>
.AuxiliaryReading .con_wrap {
  width: 100%;
  height: 1280px;
  background: url(../../assets/images/fuzhu.jpg);
  background-size: cover;
  background-position: center 0;
}
.AuxiliaryReading .con_box {
  width: 1314px;
  height: 914px;
  position: relative;
  top: 200px;
  padding-bottom: 50px;
  margin: 0 auto;
  box-sizing: border-box;
}
.con_box > .text {
  height: 100%;
  text-align: center;
}
.el-button--text {
  color: rgb(121, 0, 0);
}
.text::-webkit-scrollbar {
  width: 0 !important;
}
.text > img {
  width: 800px;
  height: 1123px;
}
</style>